'use client';

import { connectSystem } from '@app/theme';
import { ChakraProvider } from '@chakra-ui/react';
import { ColorModeProvider, type ColorModeProviderProps } from './color-mode';

export function Provider(props: ColorModeProviderProps) {
    return (
        <ChakraProvider value={connectSystem}>
            <ColorModeProvider {...props} />
        </ChakraProvider>
    );
}
