import { createSystem, defaultConfig, defineConfig, defineSlotRecipe, SystemConfig } from '@chakra-ui/react';

const brandOceanPalette = {
    50: { value: '#D4F5FF' },
    100: { value: '#AADFEE' },
    200: { value: '#80C8DD' },
    300: { value: '#55B2CC' },
    400: { value: '#2B9BBB' },
    500: { value: '#0185AA' },
    600: { value: '#016A88' },
    700: { value: '#015066' },
    800: { value: '#003544' },
    900: { value: '#001B22' },
    950: { value: '#001B22' },
};

export const alertColors = {
    success: 'green.500',
    error: 'red.500',
    warning: 'orange.500',
    info: 'blue.500',
};

const customTheme: SystemConfig = defineConfig({
    cssVarsPrefix: 'chakra',
    globalCss: {
        body: {
            height: '100%',
            color: 'fg.default',
            colorPalette: 'brand',
            bg: 'bg.canvas',
        },
        ':root': {
            '--nav-height': '67px',
            '--sidebar-width': '272px',
            '--sidebar-width-not-expanded': '64px',
            '--tabs-list-height': '42px',
            '--tabs-panel-pl': '2rem',
            '--tabs-panel-pr': '2rem',
            '--tabs-panel-pt': '1.25rem',
            '--tabs-panel-pb': '1.25rem',
            '--info-card-height': '160px',
            '--table-footer-height': '64px',
            '--modal-body-pt': '0.5rem',
            '--modal-body-pb': '0.5rem',
            '--modal-footer-height': '64px',
            '--input-border': '2px solid var(--Colors-Gray-200, #718096)',
        },
        '*::placeholder': {
            opacity: 1,
            color: 'fg.muted',
        },
        '*, *::before, &::after': {
            borderColor: 'border.default',
        },
        html: {
            color: 'fg',
            bg: 'bg',
            height: '100%',
            colorPalette: 'brand',
        },
        '#__next, #root': {
            display: 'flex',
            flexDirection: 'column',
            minH: '100%',
        },
    },
    theme: {
        tokens: {
            colors: {
                gray: {
                    25: { value: '#fcfdfe' },
                    50: { value: '#f4f8fa' },
                    950: { value: '#14151e' },
                },
                ocean: brandOceanPalette,
                ebonyClay: {
                    50: { value: '#BBC3D5' },
                    100: { value: '#9EA5B7' },
                    200: { value: '#808899' },
                    300: { value: '#636A7C' },
                    400: { value: '#454D5E' },
                    500: { value: '#282F40' },
                    600: { value: '#202633' },
                    700: { value: '#181C26' },
                    800: { value: '#10131A' },
                    900: { value: '#08090D' },
                },
                brand: brandOceanPalette,
            },
            fonts: {
                heading: { value: 'var(--font-roboto), -apple-system, system-ui, sans-serif' },
                body: { value: 'var(--font-roboto), -apple-system, system-ui, sans-serif' },
            },
            sizes: {
                11: { value: '2.75rem' },
                15: { value: '3.75rem' },
            },
            spacing: {
                4.5: { value: '1.125rem' },
            },
        },
        slotRecipes: {
            card: defineSlotRecipe({
                slots: ['root'],
                base: {
                    root: {
                        bg: 'bg.surface',
                    },
                },
            }),
        },
        textStyles: {
            xs: {
                fontSize: { value: 'xs' },
            },
            sm: {
                fontSize: { value: 'sm' },
            },
            md: {
                fontSize: { value: 'md' },
            },
            lg: {
                fontSize: { value: 'lg' },
            },
            xl: {
                fontSize: { value: 'xl' },
            },
            '2xl': {
                fontSize: { value: '2xl' },
            },
            '3xl': {
                fontSize: { value: '3xl' },
            },
            '4xl': {
                fontSize: { value: '4xl' },
                letterSpacing: { value: '-0.02em' },
            },
            '5xl': {
                fontSize: { value: '5xl' },
                letterSpacing: { value: '-0.02em' },
            },
            '6xl': {
                fontSize: { value: '6xl' },
                letterSpacing: { value: '-0.02em' },
            },
            '7xl': {
                fontSize: { value: '7xl' },
                letterSpacing: { value: '-0.02em' },
            },
        },
        semanticTokens: {
            colors: {
                'bg.canvas': {
                    value: '{colors.gray.25}',
                },
                'bg.surface': {
                    value: '{colors.white}',
                },
                'bg.subtle': {
                    value: '{colors.gray.50}',
                },
                'bg.muted': {
                    value: '{colors.gray.100}',
                },
                'fg.default': {
                    value: '{colors.gray.900}',
                },
                'fg.emphasized': {
                    value: '{colors.gray.700}',
                },
                'fg.muted': {
                    value: '{colors.gray.600}',
                },
                'fg.subtle': {
                    value: '{colors.gray.500}',
                },
                'fg.inverted': {
                    value: '{colors.white}',
                },
                'border.default': {
                    value: '{colors.gray.200}',
                },
                'border.emphasized': {
                    value: '{colors.gray.300}',
                },
                'border.active': {
                    value: '{colors.gray.400}',
                },
                'bg.accent.default': { value: '{colors.brand.600}' },
                'bg.accent.subtle': { value: '{colors.brand.500}' },
                'bg.accent.muted': { value: '{colors.brand.400}' },
                'fg.accent.subtle': { value: '{colors.brand.100}' },
                'fg.accent.muted': { value: '{colors.brand.50}' },
                'fg.accent.default': { value: '{colors.white}' },
                accent: {
                    value: '{colors.brand.500}',
                },
                success: {
                    value: '{colors.green.500}',
                },
                error: {
                    value: '{colors.red.500}',
                },
                brand: {
                    solid: { value: '{colors.brand.500}' },
                    contrast: { value: 'white' },
                    fg: { value: '{colors.brand.700}' },
                    muted: { value: '{colors.brand.400}' },
                    subtle: { value: '{colors.brand.50}' },
                    emphasized: { value: '{colors.brand.400}' },
                    focusRing: { value: '{colors.brand.500}' },
                },
            },
            shadows: {
                xs: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 1px 2px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 1px 2px rgba(13, 14, 20, 0.9)' },
                },
                sm: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 2px 4px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 2px 4px rgba(13, 14, 20, 0.9)' },
                },
                md: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 4px 8px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 4px 8px rgba(13, 14, 20, 0.9)' },
                },
                lg: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 8px 16px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 8px 16px rgba(13, 14, 20, 0.9)' },
                },
                xl: {
                    default: { value: '0px 0px 1px rgba(45, 55, 72, 0.05), 0px 16px 24px rgba(45, 55, 72,  0.1)' },
                    _dark: { value: '0px 0px 1px rgba(13, 14, 20, 1), 0px 16px 24px rgba(13, 14, 20, 0.9)' },
                },
                focus: {
                    default: { value: '0 0 0 4px #EDF2F7' },
                    _dark: { value: '0 0 0 4px #2D3748' },
                },
            },
        },
    },
});

export const connectSystem = createSystem(defaultConfig, customTheme);
